import initFormValidation from '@carwow/carwow_theme/app/javascript/helpers/form_validation'
import buildValidationRules from './validation_rules'

document.addEventListener('DOMContentLoaded', () => {
  const userConfirmationForm = document.querySelector('#user-confirmation form')
  if (!userConfirmationForm) return

  const rules = buildValidationRules(userConfirmationForm)

  initFormValidation(userConfirmationForm, rules)

  window.Analytical.track('/car_configuration/confirm-details')
})
