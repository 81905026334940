export default function buildValidationRules (form) {
  return {
    alert_emails: {
      presence: {
        message: form.dataset.translationAlertEmails
      }
    },
    advice_emails: {
      presence: {
        message: form.dataset.translationAdviceEmails
      }
    },
    feedback_emails: {
      presence: {
        message: form.dataset.translationFeedbackEmails
      }
    },
    selling_emails: {
      presence: (_, attribute) => {
        if ('selling_emails' in attribute) {
          return {
            message: form.dataset.translationSellingEmails
          }
        }
        return false
      }
    },
    selling_sms: {
      presence: (_, attribute) => {
        if ('selling_sms' in attribute) {
          return {
            message: form.dataset.translationSellingEmails
          }
        }
        return false
      }
    }
  }
}
