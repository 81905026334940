// polyfills
import 'intersection-observer'

import Rails from '@rails/ujs'
import '@carwow/carwow_theme/app/javascript/theme_common'
import 'pages/shared/communication_preferences_modal'
import 'pages/shared/confirmation_modal/javascript/validation'
import 'pages/shared/postcode_modal/javascript/postcode_modal_form'

Rails.start()
