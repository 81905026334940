import initFormValidation from '@carwow/carwow_theme/app/javascript/helpers/form_validation'
import buildValidationRules from './validation_rules'

export default function initPostcodeValidation (postcodeInputKey) {
  document.addEventListener('DOMContentLoaded', () => {
    const postcodeValidationForm = document.querySelector('[data-validate-postcode]')

    if (!postcodeValidationForm) return

    const rules = buildValidationRules(postcodeValidationForm, postcodeInputKey)
    initFormValidation(postcodeValidationForm, rules, { async: true })
  })
}
