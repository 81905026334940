import Rails from '@rails/ujs'
import initFormValidation from '@carwow/carwow_theme/app/javascript/helpers/form_validation'
import buildValidationRules from './communication_preferences_modal/javascript/validation_rules'

const gdprForm = document.querySelector('#gdpr-form')

if (gdprForm) {
  const rules = buildValidationRules(gdprForm)

  initFormValidation(gdprForm, rules, {
    submitHandler: () => {
      if (document.querySelector('input#advice_emails_yes').checked && document.querySelector('input#price_alerts_yes').checked) {
        window.Analytical.event('User marketing consent - partial high intent', {})
      }

      Rails.handleRemote.call(gdprForm, event) // eslint-disable-line no-undef
      document.querySelector('#communication-preferences-subscriptions-modal-close').click()
    }
  })

  document.querySelector('[data-keep-subscriptions]').addEventListener('click', (e) => {
    const communicationPreferencesUrl = e.target.dataset.communicationPreferencesUrl
    window.Analytical.event('User marketing consent - yes click', {})

    const csrfToken = document.querySelector('[name="csrf-token"]').content

    fetch(communicationPreferencesUrl, {
      method: 'PUT',
      credentials: 'same-origin',
      headers: {
        accept: 'application/json, text/javascript, */*; q=0.01',
        'x-csrf-token': csrfToken,
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        'x-requested-with': 'XMLHttpRequest'
      },
      redirect: 'error',
      body: new URLSearchParams({
        alert_emails: 1,
        advice_emails: 1,
        feedback_emails: 1,
        selling_emails: 1
      })
    })
  })
}
