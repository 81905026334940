export default function buildValidationRules (form, key) {
  return {
    [key]: {
      presence: {
        message: form.dataset.translationValidPostcodeRequired
      },
      validPostcode: {
        message: form.dataset.translationValidPostcodeRequired,
        url: '/postcode_sectors/check-postcode'
      }
    }
  }
}
