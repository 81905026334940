export default function buildValidationRules (form) {
  return {
    'user[first_name]': {
      presence: {
        message: form.dataset.translationEnterFirstName
      },
      notEmail: {
        message: form.dataset.translationEnterFirstName
      }
    },
    'user[last_name]': {
      presence: {
        message: form.dataset.translationEnterLastName
      },
      notEmail: {
        message: form.dataset.translationEnterLastName
      }
    },
    'user[password]': {
      presence: {
        message: form.dataset.translationEnterPassword
      },
      length: {
        minimum: 6,
        message: form.dataset.translationPasswordLength
      }
    }
  }
}
